.navbar {
    position: fixed;
    top: 0.7rem;
    right: 0.7rem;
    width: auto;
    display: flex;
    justify-content: flex-end;
    background-color: rgba(227, 223, 219, 0.80);
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    margin-bottom: 0;
}

.link {
    margin: 0.8rem;
    color: #5b4a44;
    text-decoration: none;
    font-size: 1.5em;
    transition: background-color 0.3s;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.active-link {
    text-decoration: none;
    padding-bottom: 2px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
}

.link:hover {
    background-color: #afa4a0;
}

.active-link::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right,
            transparent,
            rgba(91, 74, 68, 0.6) 20%,
            rgba(91, 74, 68, 0.9) 40%,
            rgba(91, 74, 68, 0.9) 60%,
            rgba(91, 74, 68, 0.6) 80%,
            transparent);
    border-radius: 1px;
    transform: translateX(-50%);
}

/* media query for screen widths of 600px or less */
@media (max-width: 600px) {
    .navbar {
        top: 0rem;
        right: 0rem;
        width: 100%;
        justify-content: center;
        border-radius: 10px;
        z-index: 1000;
        height: 40px;
    }

    .link {
        margin: 0.3rem;
        font-size: 1em;
        border-radius: 10px;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .navbar {
        top: 0rem;
        right: 0rem;
        width: 100%;
        justify-content: center;
        border-radius: 10px;
        z-index: 1000;
        height: 50px;
    }

    .link {
        margin: 0.5rem;
        font-size: 1.2em;
        border-radius: 10px;
    }
}