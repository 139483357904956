.CardContaner {
    animation: slideUp 1.5s ease-out forwards;
    transform: translateY(100%);
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}