.Home {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #faf9f8;
  margin: 0;
  padding-left: 2rem;
  overflow: hidden;
  color: #5b4a44;
  width: 100%
}

.Header-mobile {
  display: none;
}

.Home-main {
  display: flex;
  width: 100%;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
}

.Home-greeting-contaner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 15rem;
  width: 100%
}

.typewriter h1,
.typewriter p {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: typing 1.5s steps(40, end) forwards;
}

.typewriter h1 {
  visibility: visible;
  font-size: calc(10px + 6vmin);
}

.typewriter p {
  font-size: calc(10px + 4vmin);
  visibility: hidden;
  animation-delay: 2s;
}


@keyframes typing {
  0% {
    width: 0;
    visibility: visible;
  }

  100% {
    width: 100%;
    visibility: visible;

  }
}

@keyframes appear {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.Home-contact-button {
  padding: 1rem;
  color: #5b4a44;
  margin: 2.5rem;
  background-color: #e3dfdb;
  font-size: calc(10px + 1vmin);
  opacity: 0;
  border-radius: 40px;
  transform: translateX(50px);
  animation: appear 0.5s ease-out 4.5s forwards;
}

.Home-contact-button :hover {
  background-color: "#a2a49f"
}

.Footer-mobile {
  display: none
}

.Footer {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* media query for screen widths of 600px or less */
@media (max-width: 600px) {
  .Home {
    background-image: none;
    padding-left: 0rem;
  }

  .Home-greeting-contaner {
    margin-top: 0;
    padding-top: 0;
  }

  .Home-desktop-img {
    display: none
  }

  .Home-main {
    padding: 1rem;
    padding-top: 0rem;
  }

  .Header-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2rem
  }

  .Header-mobile img {
    display: block;
    width: 100%;
    height: auto;
  }

  .Footer {
    display: none
  }

  .Footer-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    flex-direction: column
  }
}

/* media query for screen widths of 601px to 1024px */
@media (min-width: 601px) and (max-width: 1024px) {
  .Home {
    background-image: none;
    padding-left: 0rem;
  }

  .Home-greeting-contaner {
    margin-top: 0;
    padding-top: 0;
  }

  .Home-desktop-img {
    display: none
  }

  .Home-main {
    font-size: calc(9px + 2vmin);
    margin-right: 0rem;
    padding-top: 0rem;
  }

  .Header-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2rem
  }


  .Header-mobile img {
    width: 100%;
    height: auto;
    display: block;
  }

  .Footer-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    flex-direction: column
  }

  .Footer {
    display: none
  }
}