.About {
    margin: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #5b4a44;
}

.about-me-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: 'Clarkson', Helvetica, sans-serif;
}

.photo-container {
    flex: 1;
    padding: 20px;
}

.profile-photo {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.header-text-container {
    flex: 2;
    text-align: center;
    padding-top: 20px;
}

.header-intro-text {
    margin: 0;
    letter-spacing: 1px;
    color: #5b4a44;
}

h1 {
    margin: 0;
    font-size: 3rem;
    color: #5b4a44;
}

h2 {
    margin: 20px 0;
    font-size: 2rem;
    color: #5b4a44;
}

.line {
    border-top: 2px solid #5b4a44;
    width: 50%;
    margin: 0 auto;
}